import Permission from './permission';

const directives = {
    Permission
}

const install = (Vue) => {
    Object.keys(directives).forEach((key) => {
        Vue.directive(key, directives[key])
    })
}

export { install }
  