import api from '../../network/api'

const state = () => ({
    globalValues: [],
    pageValues: {},
    currentPageValues: [],
    spOrPrj: ''
});

const getters = {

}

const mutations = {
    init(state, data){
        state.globalValues = [];
        state.pageValues = {};
    },  
    
    setGlobalValues(state, data) {
        state.globalValues = data;
    },
    setPageValues(state, data) {
        state.pageValues = data;
    },
    setSpOrPrj(state, data) {
        state.spOrPrj = data;
    },
};

const actions = {
    async getGlobalValues ({commit}) {
        const values = await api.sp.getAllPrjGlobal();
        commit('setGlobalValues',values);
    },
    async getPageValues ({commit}) {
        const values = await api.sp.getPageParamByPrjId();
        commit('setPageValues',values);
    },
}


export default { state, getters, mutations, actions }