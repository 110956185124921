import request  from '../request'



const formConfig = {
    headers:{'Content-Type':'multipart/form-data'}
};

const file = {
    getFileInfoById(data) {
        return request.get('/file/getFileInfoById', data);
    },
    uploadFile(data) {
        const forms = new FormData();
        forms.append('file', data);
        return request.upload('/file/uploadFile', forms);
    },
    uploadFiles(data) {
        const forms = new FormData();
        if(Array.isArray(data)) {
            data.map(v => {
                forms.append('files', v);
            })
        }
        return request.upload('/file/uploadFiles', forms, formConfig);
    },
}

export default file;