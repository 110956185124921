import request  from '../request'
const commonUrl = "/sp";
const sp = {
    /**
     * 空间管理-基本信息
     */
    selectAll(data) {
        return request.get(commonUrl + '/space/selectAll', data);
    },
    getAllSpaces(data) {
        return request.get(commonUrl + '/space/getAllSpaces', data);
    },
    getSpaceById(data) {
        return request.get(commonUrl + '/space/getSpaceById', data);
    },
    addSpaceInfo(data) {
        return request.post(commonUrl + '/space/addSpace', data);
    },
    updateSpaceInfo(data) {
        return request.post(commonUrl + '/space/updateSpace', data);
    },
    getDetailById(data) {
        return request.get(commonUrl + '/space/getDetailById', data);
    },
    deleteSpaceInfo(data) {
        return request.get(commonUrl + '/space/deleteSpace', data);
    },
    setCurrentSpace(data) {
        return request.get(commonUrl + '/space/setCurrentSpace', data);
    },
    getCurrentModuleType(data) {
        return request.get(commonUrl + '/space/getCurrentModuleType', data);
    },
     /**
     * 空间管理-成员
     */
    getAllMembers(data) {
        return request.get(commonUrl + '/space/getAllMembers', data);
    },
    addMember(data) {
        return request.post(commonUrl + '/space/addMember', data);
    },
    changeRole(data) {
        return request.post(commonUrl + '/space/changeRole', data);
    },
    changeExpireTime(data) {
        return request.post(commonUrl + '/space/changeExpireTime', data);
    },
    deleteMember(data) {
        return request.post(commonUrl + '/space/deleteMember', data);
    },
     /**
     * 空间管理- 操作日志
     */
    getPageSpaceLogByType(data) {
        return request.get(commonUrl + '/space/getPageSpaceLogByType', data);
    },
    getSpaceByType(data) {
        return request.get(commonUrl + '/space/getSpaceByType', data);
    },
    validateCurrentUser(data) {
        return request.get(commonUrl + '/space/validateCurrentUser', data);
    },
    /**
     * 项目管理 - 项目基本信息
     */
    getAllProjects(data) {
        return request.get(commonUrl + '/project/getAllProjects', data);
    },
    getPrjDetailById(data) {
        return request.get(commonUrl + '/project/getPrjDetailById', data);
    },
    addProject(data) {
        return request.post(commonUrl + '/project/addProject', data);
    },
    updateProject(data) {
        return request.post(commonUrl + '/project/updateProject', data);
    },
    deleteProject(data) {
        return request.get(commonUrl + '/project/deleteProject', data);
    },
    setCurrentPrjKey(data) {
         return request.get(commonUrl + '/project/setCurrentPrjKey', data);
    },
    /**
     * 项目管理 - 成员
     */
    getAllPrjMembers(data) {
        return request.get(commonUrl + '/project/getAllPrjMembers', data);
    },
    addPrjMember(data) {
        return request.post(commonUrl + '/project/addPrjMember', data);
    },
    changePrjRole(data) {
        return request.post(commonUrl + '/project/changePrjRole', data);
    },
    changePrjExpireTime(data) {
        return request.post(commonUrl + '/project/changePrjExpireTime', data);
    },
    deletePrjMember(data) {
        return request.post(commonUrl + '/project/deletePrjMember', data);
    },
     /**
     * 项目管理-全局变量
     */
    getGlobalByPage(data) {
        return request.get(commonUrl + '/project/getGlobalByPage', data);
    },
    getAllPrjGlobal(data) {
        return request.get(commonUrl + '/project/getAllPrjGlobal', data);
    },
    selectAllDBs(data) {
        return request.get(commonUrl + '/project/selectAllDBs', data);
    },
    addGlobal(data) {
        return request.post(commonUrl + '/project/addGlobal', data);
    },
    updateGlobal(data) {
        return request.post(commonUrl + '/project/updateGlobal', data);
    },
    deleteGlobal(data) {
        return request.get(commonUrl + '/project/deleteGlobal', data);
    },
    /**
     * 项目管理-数据源
     */
    getAllDBs(data) {
        return request.get(commonUrl + '/project/getAllDBs', data);
    },
    addDB(data) {
        return request.post(commonUrl + '/project/addDB', data);
    },
    updateDB(data) {
        return request.post(commonUrl + '/project/updateDB', data);
    },
    deleteDB(data) {
        return request.get(commonUrl + '/project/deleteDB', data);
    },

     /**
     * 项目管理-版本管理
     */
    getAllVersions(data) {
        return request.get(commonUrl + '/project/getAllVersions', data);
    },
    addVersion(data) {
        return request.post(commonUrl + '/project/addVersion', data);
    },
    updateVersion(data) {
        return request.post(commonUrl + '/project/updateVersion', data);
    },
    deleteVersion(data) {
        return request.get(commonUrl + '/project/deleteVersion', data);
    }, 
    getPrjLogByType(data) {
        return request.get(commonUrl + '/project/getPrjLogByType', data);
    },
    switchVersion(data) {
        return request.get(commonUrl + '/project/switchVersion', data);
    },
    getVerScreen(data) {
        return request.get(commonUrl + '/project/getVerScreen', data);
    },
    getVerDataByShareId(data) {
        return request.get(commonUrl + '/project/getVerDataByShareId', data);
    },
    rePackageVersionData(data) {
        return request.get(commonUrl + '/project/rePackageVersionData', data);
    },
    publishVersionData(data) {
        return request.post(commonUrl + '/project/publishVersionData', data);
    },
    selectAllTags(data) {
        return request.get(commonUrl + '/project/selectAllTags', data);
    },
    addTag(data) {
        return request.post(commonUrl + '/project/addTag', data);
    },
    getUserChargeRecord(data) {
        return request.get(commonUrl + '/project/getUserChargeRecord', data);
    },
    cancelPublishData(data) {
        return request.get(commonUrl + '/project/cancelPublishData', data);
    },
    applyCaseData(data) {
        return request.get(commonUrl + '/project/applyCaseData', data);
    },
     /**
     * 项目管理-大屏
     */
    getScreenByPrjId(data) {
        return request.get(commonUrl + '/project/getScreenByPrjId', data);
    }, 
    addPrjScreen(data) {
        return request.post(commonUrl + '/project/addPrjScreen', data);
    },
    getDBChartData(data) {
        return request.get(commonUrl + '/project/getDBChartData', data);
    }, 
    isConnection(data) {
        return request.get(commonUrl + '/project/isConnection', data);
    },
    getAllPublishProjects(data) {
        return request.get(commonUrl + '/project/getAllPublishProjects', data);
    },
    httpClientInterface(data) {
        return request.post(commonUrl + '/project/httpClientInterface', data);
    },
    updateAdsorption(data) {
        return request.post(commonUrl + '/project/updateAdsorption', data);
    },

    /**
     * 大屏模板 
    */
    getAllTemplate(data) {
        return request.get(commonUrl + '/template/selectAll', data);
    },
    setUpTemplate(data) {
        return request.post(commonUrl + '/template/setUpTemplate', data);
    },
    
    /**
     * 快照
    */
    selectAllSnap(data) {
        return request.get(commonUrl + '/project/selectAllSnap', data);
    },
    addSnapshot(data) {
        return request.post(commonUrl + '/project/addSnapshot', data);
    },
    updateSnapshot(data) {
        return request.post(commonUrl + '/project/updateSnapshot', data);
    },
    deleteSnapshot(data) {
        return request.get(commonUrl + '/project/deleteSnapshot', data);
    },
    previewSnapshot(data) {
        return request.get(commonUrl + '/project/previewSnapshot', data);
    },
    adjustExpire(data) {
        return request.post(commonUrl + '/project/adjustExpire', data);
    },
    saveCode(data) {
        return request.post(commonUrl + '/project/saveCode', data);
    },
    getShareInfo(data) {
        return request.get(commonUrl + '/project/getShareInfo', data);
    },
    deleteShareById(data) {
        return request.get(commonUrl + '/project/deleteShareById', data);
    },
    updateShare(data) {
        return request.post(commonUrl + '/project/updateShare', data);
    },

    /**
     * 数据模型
    */
    selectAllByPage(data) {
        return request.get(commonUrl + '/project/selectAllByPage', data);
    },
    getAllModels(data) {
        return request.get(commonUrl + '/project/getAllModels', data);
    },
    deleteModel(data) {
        return request.get(commonUrl + '/project/deleteModel', data);
    },
    addModel(data) {
        return request.post(commonUrl + '/project/addModel', data);
    },
    updateModel(data) {
        return request.post(commonUrl + '/project/updateModel', data);
    },
    getAllTableNames(data) {
        return request.get(commonUrl + '/project/getAllTableNames', data);
    },
    getDataByTable(data) {
        return request.get(commonUrl + '/project/getDataByTable', data);
    },
    getContentById(data) {
        return request.get(commonUrl + '/project/getContentById', data);
    },
    getContentsByIds(data) {
        return request.get(commonUrl + '/project/getContentsByIds', data);
    },

    /**
     * 分页
    */
    addScreenPage(data) {
        return request.post(commonUrl + '/project/addScreenPage', data);
    },
    updateScreenPage(data) {
        return request.post(commonUrl + '/project/updateScreenPage', data);
    },
    deleteScreenPage(data) {
        return request.get(commonUrl + '/project/deleteScreenPage', data);
    },


    /**
     * 参数
    */
     getPageParamByPrjId(data) {
        return request.get(commonUrl + '/project/getPageParamByPrjId', data);
     },

     getRelationInfos(data) {
        return request.get(commonUrl + '/project/getRelationInfos', data);
     },

     /**
      * 管理界面-标签管理
     */
     searchTags(data) {
        return request.get(commonUrl + '/admin/searchTags', data);
     },
     addTag(data) {
        return request.post(commonUrl + '/admin/addTag', data);
     },
     updateTag(data) {
        return request.post(commonUrl + '/admin/updateTag', data);
     },
     deleteTag(data) {
        return request.get(commonUrl + '/admin/deleteTag', data);
     },
     searchPubVerInfo(data) {
        return request.get(commonUrl + '/admin/searchPubVerInfo', data); 
     },
     resolveRecord(data) {
        return request.post(commonUrl + '/admin/resolveRecord', data);
     },
     rejectRecord(data) {
        return request.post(commonUrl + '/admin/rejectRecord', data);
     },
     appealRecord(data) {
        return request.post(commonUrl + '/admin/appealRecord', data);
     },
     selectAllRecords(data) {
        return request.get(commonUrl + '/admin/selectAllRecords', data); 
     },
     selectAppealRecords(data) {
        return request.get(commonUrl + '/admin/selectAppealRecords', data); 
     },
     searchUserInfos(data) {
        return request.get(commonUrl + '/admin/searchUserInfos', data); 
     },
     moveTagsPos(data) {
        return request.get(commonUrl + '/admin/moveTagsPos', data); 
     },
     getAutoApproveStatus(data) {
        return request.get(commonUrl + '/admin/getAutoApproveStatus', data);
     },
     setAutoApprove(data) {
        return request.get(commonUrl + '/admin/setAutoApprove', data);
     },
}

export default sp;