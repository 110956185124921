<template>
  <el-config-provider :locale="locale">
    <div class="base">
      <router-view v-slot="{ Component }">
          <component :is="Component" />
      </router-view>
    </div>
  </el-config-provider>
</template>

<script>
import { ACCESS_TOKEN_KEY, COMMON_DES_KEY } from "./common/keys";
import CryptoJS from "./assets/js/crypto-js.min";
import Tools from "./assets/js/tools"
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { onMounted } from "vue";
import { useStore } from "vuex";

window.CryptoJS = CryptoJS;
window.Tools = Tools;

export default {
  name: "app",
  components: {
    ElConfigProvider,
  },
  setup () {
    const $store = useStore();
    
    const accessToken = Tools.getLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY);

    onMounted(() => {
      if (accessToken) {
        $store.dispatch("setToken", accessToken);
      }
    })
    return {
      locale: zhCn,
    }
  }
};
</script>

<style lang="scss">
@import url(./assets/css/base.css);
</style>
