import { createRouter, createWebHistory } from "vue-router";

//主页面
const Index = () => import("views/index/Index");
const Home = () => import("views/index/Home"); //首页
const Cases = () => import("views/index/Cases"); //案例
const Privacy = () => import("views/register/Privacy"); //案例

//项目选择
const Mgt = () => import("views/mgt/Mgt");
const ProjectsMgt = () => import("views/mgt/projectsMgt/ProjectsMgt"); //项目管理
const ProjectsAdd = () => import("views/mgt/projectsMgt/ProjectsAdd"); //新增项目
const SpaceMgt = () => import("views/mgt/spaceMgt/SpaceMgt"); //空间管理
const SpaceAdd = () => import("views/mgt/spaceMgt/SpaceAdd"); //新增空间
const Market = () => import("views/mgt/market/Market"); //大屏市场

//主面板
const Main = () => import("views/main/Main");
const Preview = () => import("views/main/preview/Preview"); //预览
const OperationLog = () => import("views/main/operationLog/OperationLog"); //操作日志
const Versions = () => import("views/main/versions/Versions"); //版本
const DataSource = () => import("views/main/dataSource/DataSource"); //数据源
const DataIntegration = () =>
  import("views/main/dataIntegration/DataIntegration"); //数据整合
const DataModel = () => import("views/main/dataModel/DataModel"); //数据整合
const GlobalVariable = () => import("views/main/globalVariable/GlobalVariable"); //全局变量
const SharingMgt = () => import("views/main/sharingMgt/SharingMgt"); //分享管理
const MemberMgt = () => import("views/main/memberMgt/MemberMgt"); //成员管理
const Setting = () => import("views/main/setting/Setting"); //设置
const SpaceDetail = () => import("views/main/spaceDetail/SpaceDetail"); //空间详情
const SpaceLog = () => import("views/main/spaceLog/SpaceLog"); //空间日志
const PrjSetting = () => import("views/main/setting/PrjSetting"); //项目设置
const SnapshotVersion = () => import("views/main/snapshot/SnapshotVersion"); //快照
const Share = () => import("views/main/share/Share"); //分享
const Relation = () => import("views/main/relation/Relation"); //血缘关系

const ScreenPreview = () => import("views/outerChain/screen/screenPreview"); //分享页

//管理页面
const Manage = () => import("views/manage/Manage");
const UserManagement = () => import("views/manage/user/UserManagement"); //用户管理
const CaseManagement = () => import("views/manage/case/CaseManagement"); //案例审批
const MarketManagement = () => import("views/manage/market/MarketManagement"); //市场审批
const TagManagement = () => import("views/manage/tag/TagManagement"); //标签管理

const UserInfo = () => import("views/userInfo/userInfo"); //个人信息

const routes = [
  {
    path: "",
    redirect: "/Index"
  },
  {
    path: "/",
    component: Index,
    meta: {
      keepAlive: false //设置页面是否需要使用缓存
    },
    children: [
      {
        path: "/Index",
        component: Home,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "/Cases",
        component: Cases,
        meta: {
          keepAlive: false
        }
      }
    ]
  },
  {
    path: "/Mgt",
    name: "Mgt",
    component: Mgt,
    children: [
      {
        path: "",
        redirect: "/Mgt/ProjectsMgt"
      },
      {
        path: "ProjectsMgt",
        component: ProjectsMgt,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "ProjectsAdd",
        name: "ProjectsAdd",
        component: ProjectsAdd,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "SpaceMgt",
        component: SpaceMgt,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "SpaceAdd",
        name: "SpaceAdd",
        component: SpaceAdd,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Market",
        component: Market,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "UserInfo",
        component: UserInfo,
        meta: {
          keepAlive: false
        }
      }
    ]
  },
  {
    path: "/Main",
    component: Main,
    children: [
      {
        path: "",
        redirect: "/Main/Preview"
      },
      {
        path: "Preview",
        component: Preview,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "OperationLog",
        component: OperationLog,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Versions",
        component: Versions,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Snapshot",
        component: SnapshotVersion,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Share",
        component: Share,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "SpaceDetail",
        component: SpaceDetail,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "SpaceLog",
        component: SpaceLog,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Relation",
        component: Relation,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "DataSource",
        component: DataSource,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "DataIntegration",
        component: DataIntegration,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "DataModel",
        component: DataModel,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "GlobalVariable",
        component: GlobalVariable,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "SharingMgt",
        component: SharingMgt,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "MemberMgt",
        component: MemberMgt,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "Setting",
        component: Setting,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "PrjSetting",
        component: PrjSetting,
        meta: {
          keepAlive: false
        }
      }
    ]
  },
  {
    path: "/Privacy",
    component: Privacy,
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/ScreenPreview",
    component: ScreenPreview,
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/Manage",
    name: "Manage",
    component: Manage,
    children: [
      {
        path: "",
        redirect: "/Manage/UserManagement"
      },
      {
        path: "UserManagement",
        component: UserManagement,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "CaseManagement",
        name: "CaseManagement",
        component: CaseManagement,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "MarketManagement",
        component: MarketManagement,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "tagManagement",
        component: TagManagement,
        meta: {
          keepAlive: false
        }
      },
      {
        path: "UserInfo",
        component: UserInfo,
        meta: {
          keepAlive: false
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
