import { createStore } from 'vuex'
import vuexAlong from 'vuex-along'

import {ACCESS_TOKEN_KEY,COMMON_DES_KEY} from '../common/keys'
import api from '../network/api'

const files = require.context('./modules', false, /\.js$/);
const modules = {};
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
});
Object.keys(modules).forEach((key) => {
  modules[key]['namespaced'] = true
});

export default createStore({
  state: {
    user: undefined,
    trace: false,
    currentParam: {
      memberListObj: {},
      prjMemberListObj: {},
    },
  },
  getters: {
  },
  mutations: {
    setUserInfo (state, info) {
      state.user = info;
    },
    setSpaceMember (state, obj) {
      state.currentParam.memberListObj[obj.id] = obj.list;
      // state.currentParam.memberList = list;
    },
    setProjectMember (state, obj) {
      state.currentParam.prjMemberListObj[obj.id] = obj.list;
      // state.currentParam.prjMemberList = list;
    },
    setTrace (state, info) {
      state.trace = info;
    },
  },
  actions: {
    async setToken ({commit},token) {
      Tools.setLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY, token);
      const info = await api.auth.info();
      const trace = await api.auth.getTrace();
      commit('setUserInfo',info);
      commit('setTrace',trace);
    },
    async signOut({commit}) {
      await api.auth.signOut();
      Tools.removeLocal();
      commit('setUserInfo',undefined);
      commit('setTrace',false);
    }
  },
  modules,
  plugins: [vuexAlong]
})
