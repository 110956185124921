const hasPermission = (self, data) => {
    let roleInfos = self.$store.state.user.roleInfos;
    if(roleInfos && roleInfos.length > 0) {
       let roleId = roleInfos[0].id;
       if("-2" == roleId || "0" == roleId) {
         return true;
       }
    }
    return false;
}

const setPermission = (data) => {

}

const permission = {
    mounted(el, binding) {
        let args = binding.value, self = binding.instance;
        let hasPerm = hasPermission(self, args);
        // 没有权限 移除Dom元素
        if(!hasPerm)
            el.parentNode && el.parentNode.removeChild(el)
    }
}


permission.install = (Vue) => {
    Vue.directive('Permission', this);
}

permission.setPerm = (data) => {
    setPermission(data);
}
export default permission;