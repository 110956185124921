import request  from '../request'
import {SIGN_IN_DES_KEY} from '../../common/keys';
const SIGN_IN_OPTS = {
    headers : {
        "Authorization": "Basic cGM6MTIzNDU2",
    }
};

const auth = {
    signIn (username,password) {
        password = Tools.encryptByDES(password, SIGN_IN_DES_KEY);
        return request.post('/auth/signIn',{username,password},SIGN_IN_OPTS);
    },
    info () {
        return request.get('/auth/getInfo');
    },
    signOut () {
        return request.post('/auth/signOut');
    },
    selectAllUser () {
        return request.get('/auth/user/selectAllUser');
    },
    registerUser (data) {
        return request.postIgToken('/auth/user/registerUser', data);
    },
    forgetPassword (data) {
        return request.postIgToken('/auth/user/forgetPassword', data);
    },
    sendMessage (data) {
        return request.postIgToken('/auth/sms/sendMessage', data);
    },
    sendMailCode (data) {
        return request.postIgToken('/auth/sms/sendMailCode', data);
    },
    generatorCaptcha () {
        return request.getIgToken('/auth/generatorCaptcha');
    },
    verifyCaptcha (data) {
        return request.postIgToken('/auth/verifyCaptcha', data);
    }, 
    codeValidate (data) {
        return request.postIgToken('/auth/user/codeValidate', data);
    },
    getTrace (data) {
        return request.get('/auth/user/getTrace', data);
    },
    changeTrace (data) {
        return request.get('/auth/user/changeTrace', data);
    },
    changePass (data) {
        return request.post('/auth/user/changePass', data);
    },
    adjustUserLimitNum (data) {
        return request.post('/auth/admin/adjustUserLimitNum', data);
    },
    updateUserConfig (data) {
        return request.post('/auth/user/updateUserConfig', data);
    },
    getFilterUserWithoutPage (data) {
        return request.get('/auth/user/getFilterUserWithoutPage', data);
    },
    changeNickName (data) {
        return request.post('/auth/user/changeNickName', data);
    },
    changeIcon (data) {
        return request.post('/auth/user/changeIcon', data);
    },
    changePhoneOrMail (data) {
        return request.post('/auth/user/changePhoneOrMail', data);
    },
    sendMsg (data) {
        return request.postIgToken('/auth/sms/sendMsg', data);
    },
    validateMsgCode (data) {
        return request.postIgToken('/auth/sms/validateMsgCode', data);
    }, 
}

export default auth;