const state = () => ({
    userScale: {},
    prjModelList: [],
    project: {},
    spOrPrj: '',
});

const getters = {
}

const mutations = {
    init(state, data){
        state.userScale = {};
    },  
    
    setUserScale(state, data) {
        state.userScale[data.userId] = data.scale;
    },

    setPrjModelList(state, data) {
        state.prjModelList = data;
    },

    setProject(state, data) {
        state.project = data;
    },

    setSpOrPrj(state, data) {
        state.spOrPrj = data;
    }
};

const actions = {
    
}


export default { state, getters, mutations, actions }