import request  from '../request'
const commonUrl = "/sp";
const sp = {
    /**
     * 分享
     */
    getScreenByPrjId(data) {
        return request.get(commonUrl + '/share/getScreenByPrjId', data);
    },
    getVerScreen(data) {
        return request.get(commonUrl + '/share/getVerScreen', data);
    },
    getAllModels(data) {
        return request.get(commonUrl + '/share/getAllModels', data);
    },
    getVerDataByShareId(data) {
        return request.get(commonUrl + '/share/getVerDataByShareId', data);
    },
    adjustExpire(data) {
        return request.post(commonUrl + '/share/adjustExpire', data);
    },
}

export default sp;