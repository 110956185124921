import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import api from "./network/api";
import axios from "axios";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import { install } from "./components/directives/perm";
import "animate.css";
import myUi from 'vue-vls';

let app = createApp(App);
app.config.unwrapInjectedRef = true;
app.config.globalProperties.$bus = new mitt(); //绑定数据总线
app.config.globalProperties.axios = axios;
app.config.globalProperties.$api = api;
app.config.globalProperties.svg = `<path class="path" d="
  M 30 15
  L 28 17
  M 25.61 25.61
  A 15 15, 0, 0, 1, 15 30
  A 15 15, 0, 1, 1, 27.99 7.5
  L 15 15
  " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`;
app.config.globalProperties.deepClone = function (obj) {
  var objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && typeof obj[key] === "object")
          objClone[key] = this.deepClone(obj[key]);
        else objClone[key] = obj[key];
      }
    }
  }
  return objClone;
};
install(app);
app.use(store).use(router).use(myUi).mount("#app");
